import React from "react";
import {ReactSVG} from 'react-svg'


const getPath = (filename) => {
    return `/icons/clipping/${filename}.svg`
};

export const AddCircleIcon = props => (
    <ReactSVG src={getPath('add_circle')}/>
);
export const AddFormIcon = props => (
    <ReactSVG src={getPath('add_form')}/>
);
export const AddRecIcon = props => (
    <ReactSVG src={getPath('add_rec')}/>
);
export const ClippingIcon = props => (
    <ReactSVG src={getPath('clipping')}/>
);
export const CropIcon = props => (
    <ReactSVG src={getPath('crop')}/>
);
export const CurrentColorIcon = props => (
    <ReactSVG src={getPath('current_color')}/>
);
export const DeleteIcon = props => (
    <ReactSVG src={getPath('delete')} style={{width: 20, height: 20}}/>
);
export const EditIcon = props => (
    <ReactSVG src={getPath('edit')}/>
);
export const MakeCoverIcon = props => (
    <ReactSVG src={getPath('make_cover')}/>
);
export const MakeCoverIconSelected = props => (
    <ReactSVG src={getPath('make_cover_selected')}/>
);
export const CoverIcon = props => (
    <ReactSVG src={getPath('cover')}/>
);
export const MetricTypeDropdownIcon = props => (
    <ReactSVG src={getPath('metrictype_dropdown')}/>
);
export const MetricTypeNumberIcon = props => (
    <ReactSVG src={getPath('metrictype_number')}/>
);
export const MetricTypeSliderIcon = props => (
    <ReactSVG src={getPath('metrictype_slider')}/>
);
export const MetricTypeSwitchIcon = props => (
    <ReactSVG src={getPath('metrictype_switch')}/>
);
export const MetricTypeTypeIcon = props => (
    <ReactSVG src={getPath('metrictype_type')}/>
);
export const MirrorHorizontalIcon = props => (
    <ReactSVG src={getPath('mirror_horizontal')}/>
);
export const MirrorVerticalIcon = props => (
    <ReactSVG src={getPath('mirror_vertical')}/>
);
export const OpenNewTabIcon = props => (
    <ReactSVG src={getPath('open_new_tab')} style={{width: 20, height: 20}}/>
);
export const RotateIcon = props => (
    <ReactSVG src={getPath('rotate')}/>
);
export const SwitchPositionIcon = props => (
    <ReactSVG src={getPath('switch_position')}/>
);
export const TurnLeftIcon = props => (
    <ReactSVG src={getPath('turn_left')}/>
);
export const TurnRightIcon = props => (
    <ReactSVG src={getPath('turn_right')}/>
);
export const SpyIcon = props => (
    <ReactSVG src={getPath('spy')}/>
);

export const DropFailureIcon = props => (
    <ReactSVG src={getPath('drop_failure')}/>
)

export const CalendarIcon = props => (
    <ReactSVG src={getPath('icon_calendar')}/>
);

export const ColumnsIcon = props => (
    <ReactSVG src={getPath('columns')}/>
);


export const FilterIcon = props => (
    <ReactSVG src={getPath('filter')}/>
);

export const PreviewClipIcon = props => (
    <ReactSVG src={getPath('preview')}/>
);

export const ExportIcon = props => (
    <ReactSVG
        beforeInjection={svg => {
            props.transform && svg.setAttribute('transform', props.transform)
        }}
        src={getPath('export')}/>
)

export const CheckIcon = props => (
    <ReactSVG
        beforeInjection={svg => {
            props.width && svg.setAttribute('width', props.width)
            props.height && svg.setAttribute('height', props.height)
        }}
        src={getPath('check')}
    />
)