import {createSlice} from '@reduxjs/toolkit';

import {ClippingFileUploader} from "../ClippingFileUploader";
import {Clipping, ClippingQuery} from "@sascha_kirstein/aclipp-models";

export const DATAGRID_DEFAULT_INITIAL_STATE = {
    sorting: {
        sortModel: [{field: 'created', sort: 'desc'}], //to show sorting button by default we need to do initial sorting =)
    },
    columns: {
        columnVisibilityModel: {
            "clip": false,
            "tag": false,
            "highlight": false
        }
    }
}

const initialState = {
    current: new Clipping({status: Clipping.STATUS.COMPLETE}),
    fileUploader: new ClippingFileUploader(),
    uploading: null,
    total: 1,
    transferred: 0,
    data: {},
    ordered: [],
    keys: [],
    printData: [],
    onlineData: {},
    query: new ClippingQuery({descendingOrder: true}),
    allDateAgencyID: "",
    allData: {},
    allOrdered: [],
    allKeys: [],
    searchInput: [],
    searchActive: false,
    searchResult: [],
    loading: true,
    action: null,
    dataGridState: null,
    dataGridDateRange: [null, null],
    dataGridColumn: [],
};


const orderedData = (data, {orderBy, descendingOrder}) => {
    orderBy = orderBy[0] || 'created';
    const ordered = Object.values(data).sort((a, b) => {
        return a[orderBy] - b[orderBy];
    })

    if (descendingOrder) {
        ordered.reverse()
    }

    return ordered;
};

const clippingSlice = createSlice({
    name: 'clipping',
    initialState,
    reducers: {
        loadClipping: (state, {type, payload}) => {
            state.action = type;
            state.data = payload.data;
            state.ordered = payload.ordered;
            state.keys = Object.keys(payload.data);
        },
        getAllClipping: (state, {type, payload}) => {
            state.action = type;
            state.allData = payload.data;
            state.allOrdered = orderedData(payload.data, state.query);
            state.allKeys = Object.keys(payload.data);
            state.allDateAgencyID = payload.agencyID;
            state.loading = false;
        },
        loadPrintData: (state, {type, payload}) => {
            state.action = type;
            state.printData = payload.printData;
        },
        setCurrentClipping: (state, {type, payload}) => {
            state.action = `${type}(${Math.random()})`;
            state.current = payload.current;
        },
        clippingUploadProcess: (state, {type, payload}) => {
            state.action = type;
            state.uploading = payload.clipping;
            state.total = payload.total;
            state.transferred = payload.transferred;
        },
        clippingUploadSuccess: (state, {type}) => {
            state.action = type;
            state.uploading = null;
            state.total = 0;
            state.transferred = 0;
        },
        loadOnlineData: (state, {type, payload}) => {
            state.action = type;
            state.onlineData = payload.onlineData;
        },
        updateQuery: (state, {type, payload}) => {
            state.action = `${type}(${Math.random()})`;
            state.query = payload.query;
        },
        setSearchInput: (state, {type, payload}) => {
            state.action = type;
            state.searchActive = payload.searchInput.length > 0;
            state.searchInput = payload.searchInput;
        },
        setSearchResult: (state, {type, payload}) => {
            state.action = type;
            state.searchResult = payload.searchResult;
        },
        setDateGridState: (state, {type, payload}) => {
            state.action = type;
            state.dataGridState = payload.state;
        },
        setDateGridDateRange: (state, {type, payload}) => {
            state.action = `${type}(${Math.random()})`;
            state.dataGridDateRange = payload.dateRange;
        },
        setClippingColumn: (state, {type, payload}) => {
            state.action = type;
            state.dataGridColumn = payload.column;
            state.dataGridState = DATAGRID_DEFAULT_INITIAL_STATE;
            try {
                const actionFieldIndex = payload.column.findIndex((c) => c.field === 'action')
                const visibilityModel = {...state.dataGridState.columns.columnVisibilityModel};
                for (let i = actionFieldIndex + 1 ; i < payload.column.length; i++){
                    visibilityModel[payload.column[i].field] = false;
                }
                console.log({visibilityModel})
                state.dataGridState.columns.columnVisibilityModel = visibilityModel;
            }
            catch (e) {
                console.log(e)
            }

        },
        UPDATE_OR_CREATE_SUCCESS: (state, {type}) => {
            state.action = type;
        },
        CREATE_CLIPPING_SUCCESS: (state, {type}) => {
            state.action = type;
        },
        UPDATE_CLIPPING_SUCCESS: (state, {type}) => {
            state.action = type;
        },
        DELETE_CLIPPING_SUCCESS: (state, {type}) => {
            state.action = type;
        },
        RESTORE_CLIPPING_SUCCESS: (state, {type}) => {
            state.action = type;
        }
    }
});

export const clippingAction = clippingSlice.actions;
export default clippingSlice.reducer;