import { createSlice } from '@reduxjs/toolkit';
import {Analytic} from '@sascha_kirstein/aclipp-models';

const initialState = {
    current: new Analytic(),
};

const analyticSlice = createSlice({
    name: 'analytic',
    initialState,
    reducers: {
        setCurrentAnalytic: (state, {payload: analytic}) => {
            state.current = analytic;
        }
    }
});


export const analyticAction = analyticSlice.actions;
export default analyticSlice.reducer;