import React from 'react';
import {useTranslation} from "react-i18next";
import makeStyles from '@mui/styles/makeStyles';
import ButtonBase from "@mui/material/ButtonBase";
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import Typography from "@mui/material/Typography";
import {setCurrentClipping} from "../../store/actions/clippingActions";
import {Aclipp, Clipping} from "@sascha_kirstein/aclipp-models";
import {PlusIcon} from "../icon/icons";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';

MenuClippingButton.propTypes = {};

MenuClippingButton.defaultProps = {};

const WARNING_THRESHOLD = 0.8 // 80%

export default function MenuClippingButton(props) {
    const classes = useStyles();
    const {t} = useTranslation('layout');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const menuIsExtended = useSelector(state => state.app.menuExtended);
    const monitorState = useSelector(state => state.monitor);
    const monitor = monitorState.current
    const {clippingLimit = 0, clippingUsage = 0} = monitor.getUsage();
    const usageRatio = clippingLimit === 0 ? 1 : clippingUsage / clippingLimit;
    const clippingsLeft = clippingLimit - clippingUsage;

    if (!monitorState.isLoaded) {
        return (
            <ButtonBase
                className={classes.buttonBase}
                style={{border: '1px solid #A5A4BF'}}
            >
            </ButtonBase>
        );
    }

    // Limit reached
    if (clippingUsage >= clippingLimit) {
        return (
            <ButtonBase
                className={classes.buttonBase}
                style={{border: '1px solid #E61F5C', backgroundColor: '#E61F5C'}}
                onClick={() => {
                    try {
                        window.Intercom('showNewMessage', t('MenuClippingButton.increaseLimit'))
                    } catch (e) {
                        console.log("no intercom object")
                    }
                }}
            >
                <div style={{position: 'absolute', left: 12}}>
                    <WarningIcon style={{padding: '4px 0px 0px 0px'}}/>
                </div>

                <Typography
                    align={'left'}
                    noWrap
                    className={classes.createLabel}
                    style={menuIsExtended ?
                        {width: '100%', opacity: 1, transition: 'opacity 0.225s ease-in-out 0.195s'} :
                        {width: 0, opacity: 0}}
                >
                    {t('MenuClippingButton.limitReached')}
                </Typography>
            </ButtonBase>
        )
    }


    return (
        <ButtonBase
            className={classes.buttonBase}
            style={usageRatio >= WARNING_THRESHOLD ? {border: '1px solid #E61F5C'} : {border: '1px solid #A5A4BF'}}
            onClick={() => {
                dispatch(setCurrentClipping(new Clipping({status: Clipping.STATUS.COMPLETE}), 'MenuDrawer'));
                navigate(Aclipp.ROUTES.CLIPPING_EDIT);
            }}
        >
            <div style={{position: 'absolute', left: 12}}>
                <PlusIcon style={{padding: '4px 0px 0px 0px'}}/>
            </div>

            <Typography
                align={'left'}
                noWrap
                className={classes.createLabel}
                style={menuIsExtended ?
                    {width: '100%', opacity: 1, transition: 'opacity 0.225s ease-in-out 0.195s'} :
                    {width: 0, opacity: 0}}
            >
                {usageRatio < WARNING_THRESHOLD ?
                    t('MenuClippingButton.createClipping') :
                    t('MenuClippingButton.limitNearlyReached').replace("%", '' + clippingsLeft)
                }
            </Typography>
        </ButtonBase>
    );
}

const useStyles = makeStyles(theme => ({

    buttonBase: {
        margin: '22px 8px 22px 8px',
        flexShrink: 0,
        width: 'calc(100% - 16px)',
        height: 56,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    createLabel: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        paddingLeft: 56,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: 0,
            delay: theme.transitions.duration.leavingScreen
        })
    }
}));