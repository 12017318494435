import { createSlice } from '@reduxjs/toolkit';
import {Agency} from "@sascha_kirstein/aclipp-models";

export const startAgencyID =  'agencyID'

const initialState = {
    current: new Agency(),
    signedInAgency: null,
    agencyID: startAgencyID,
    data: {},
    ordered: [],
    keys: [],
    isLoaded: false,
};

const agencySlice = createSlice({
    name: 'agency',
    initialState,
    reducers: {
        loadAgency: (state, {payload}) => {
            state.data = payload.data;
            state.ordered = Object.values(payload.data);
            state.keys = Object.keys(payload.data);
            state.signedInAgency = payload.signedInAgency;
            state.isLoaded = true;
        },
        setAgency: (state, {payload}) => {
            state.agencyID = payload.agencyID || 'agencyID';
            state.signedInAgency =  payload.signedInAgency;
        },
    },
});


export const agencyAction = agencySlice.actions;
export default agencySlice.reducer;