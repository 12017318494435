import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    agencyData: {},
    agencyOrdered: [],
    agencyKeys: []
};

const superUserSlice = createSlice({
    name: 'superUser',
    initialState,
    reducers: {
        loadAgencies: (state, {payload: data}) => {
            state.agencyData = data;
            state.agencyOrdered = Object.values(data);
            state.agencyKeys = Object.keys(data);
        },
    }
});
//TODO error reducer was removed, I think errors can be maintained in portal with using errorSLice(server Response)

export const superUserAction = superUserSlice.actions;
export default superUserSlice.reducer;
