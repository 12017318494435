import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import LinearProgress from "@mui/material/LinearProgress";

const drawerWidth = 256;


export default function Loading() {

    const classes = useStyles();

    return (
        <div className={classes.main}>
            <p className={classes.topNav}/>
            <p className={classes.Menu}/>
            <div className={classes.overlay}>
                <div className={classes.logoHeading}>
                    <img src={'/icons/aclipp/aclipp.png'} className={classes.logo} alt='aclipp-logo'/>
                    <LinearProgress
                        variant="query"
                        color="secondary"
                        className={classes.progressBar}
                    />
                </div>
            </div>
        </div>
    )
}


const useStyles = makeStyles(theme => ({
    main: {
        flexGrow: 1,
        position: 'fixed',
        backgroundColor: '#F0F0F7',
        height: "100%",
        width: '100%',
    },
    topNav: {
        position: 'fixed',
        margin: 0,
        width: '100%',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.04)',
        backgroundColor: '#FFF',
        ...theme.mixins.toolbar,
    },
    Menu: {
        position: "absolute",
        width: drawerWidth,
        height: '100%',
        top: 0,
        left: 0,
        margin: 0,
        backgroundColor: theme.palette.primary.main,
    },
    overlay: {
        position: "absolute",
        top: 0,
        left: 0,
        margin: 0,
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(255,255,255,0.3)',
    },
    logoHeading: {
        position: 'relative',
        top: "40%"
    },
    logo: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        height: 40
    },
    progressBar: {
        marginTop: 16,
        margin: 'auto',
        width: '40%',
        align: 'center',
    }

}));