import { createSlice } from '@reduxjs/toolkit';
import {Client} from "@sascha_kirstein/aclipp-models"; //Brands

const initialState = {
    current: new Client(),
    data: {},
    ordered: [],
    keys: [],
    isLoaded: false,
};

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        loadClient: (state, {payload: clients}) => {
            state.data = clients;
            state.ordered = Object.values(clients);
            state.keys = Object.keys(clients);
            state.isLoaded = true;
        },
        setCurrentClient: (state, {payload: client}) => {
            state.current = client;
        },
    }
});

export const clientAction = clientSlice.actions;
export default clientSlice.reducer;