import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    menuExtended: true,
    config: {},
    configLoaded: false,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setMenuExtended: (state, {payload}) => {
            state.menuExtended = payload.isExtended;
        },
        loadConfigFromLocalStorage: (state,{payload}) => {
            state.config = payload.config;
            state.configLoaded = true;
        },
        setConfigSuccess: (state, {payload}) => {
            state.config = payload.config;
        },
    }
});

export const appAction = appSlice.actions;
export default appSlice.reducer;