import {User} from '@sascha_kirstein/aclipp-models';
import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    action: null,
    error: null,
    current: new User(),
    superUser: false,
    data: {},
    ordered: [],
    keys: [],
    isLoaded: false
};

const userSlice = createSlice({
    name:'user',
    initialState,
    reducers:{
        setCurrentUser: {
            reducer: (state, {type, payload}) => {
                state.current = payload.current;
                state.superUser = !!payload.superUser
            },
            prepare: (payload) => {
                const name = payload.current.firstName + " " + payload.current.lastName;
                payload.current.name = name.trim();
                return {payload}
            }
        },
        loadUser: (state,{type,payload}) => {
            state.data = payload;
            state.ordered = Object.values(payload); //immer don't like that. Use Object.values in components
            state.keys = Object.keys(payload);
            state.isLoaded = true;
        },
    }
});

export const userAction = userSlice.actions;
export default userSlice.reducer;