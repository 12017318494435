import {createSlice} from "@reduxjs/toolkit";
import {Report} from "@sascha_kirstein/aclipp-models"

const initialState = {
    count: 0,
    error: null,
    current: new Report({
        status: Report.STATUS.INIT
    }),
    data: {},
    ordered: [],
    keys: []
};

const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        loadReportSuccess: {
            reducer: (state, {payload}) => {
            state.error = null;
            state.data = payload.data;
            state.ordered = Object.values(payload.data);
            state.keys = Object.keys(payload.data);
            },
            // prepare: (payload) => {
            //     payload.ordered = Object.values(payload.data);
            //     payload.keys = Object.keys(payload.data);
            //     return {payload};
            // },
        },
        setReportSuccess: (state, {type,payload}) => {
            state.count += 1;
            state.error = null;
            state.current = payload.current;
        },

        reportError: (state, {type,payload} ) => {
            // state.action will be => report/PathCRUDReportError
            state.action = type.replace('/', `/${payload.path}`);
            state.error = payload.error;
        },

    },
});
//Webstorm doesn't load helpers if export in such way =(
export const reportAction = reportSlice.actions;
export default reportSlice.reducer;