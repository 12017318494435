import makeStyles from '@mui/styles/makeStyles';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import React, {Fragment, useEffect, useState} from "react";
import {Client} from "@sascha_kirstein/aclipp-models";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {CheckIcon} from "../icon/ClippingIcons";
import LinearProgress from "@mui/material/LinearProgress";


export default function UploadSnackBar() {
    const classes = snackbar();
    const {t} = useTranslation('layout');

    const clipping = useSelector(state => state.clipping);
    const clients = useSelector(state => state.client.data);

    const [text, setText] = useState('');
    const [uploading, setUploading] = useState(false);

    const [open, setOpen] = useState(false);
    const [progess, setProgress] = useState(0);

    useEffect(
        () => {
            if (clipping.uploading != null) {
                let clientName = clients[clipping.uploading.client].name;
                const truncate = clientName.length > 15 ? `${clientName.substring(0, 12)}...` : clientName;

                if (clipping.fileUploader.taskCount > 1) {
                    let count = clipping.fileUploader.taskCount;

                    setText(t('MenuDrawer.uploadClipping_prefix')
                        + truncate
                        + " (+" + (count - 1) + t('MenuDrawer.uploadClipping_post') + ")"
                    );
                } else {
                    setText(t('MenuDrawer.uploadClipping_prefix') + truncate);
                }
                setUploading(true);
                setOpen(true);
            } else {
                setUploading(false);
                setText(t('MenuDrawer.uploadClippingSuccessfully'));
            }

            if (uploading) {
                const interval = setInterval(() => {
                    setOpen(false);
                    setUploading(false);
                    clearInterval(interval);
                }, 3000);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [clipping.uploading, clipping.fileUploader]
    );

    useEffect(() => {
        if(!isNaN(100 * clipping.transferred / clipping.total)) {
            setProgress(100 * clipping.transferred / clipping.total);
        }
    }, [clipping.transferred, clipping.total]);

    useEffect(() => {
        if(open === false) {
            setProgress(0)
        }
    }, [open])

    return (
        <Snackbar
            className={classes.snackbar}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            open={open}
        >
            <SnackbarContent
                className={classes.snackbarContent}
                elevation={0}
                message={
                    <Fragment>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >
                            <Grid>
                                <Typography
                                    className={classes.typography}
                                    align='center'
                                >
                                    {text}
                                </Typography>
                            </Grid>
                            <Grid>
                                {!clipping.uploading && <CheckIcon/>}
                            </Grid>
                        </Grid>

                        <LinearProgress
                            variant="determinate"
                            value={progess}
                            color="secondary"/>
                    </Fragment>
                }
            />
        </Snackbar>
    );
}

const snackbar = makeStyles(theme => ({
    snackbarContent: {
        marginTop: 50,
        backgroundColor: '#f0f0f7',
        color: theme.palette.primary.main,
        width: '100%'
    },
    snackbar: {
        width: 300
    },
    typography: {
        marginBottom: 4
    }
}));

