import { createSlice } from '@reduxjs/toolkit';
import {MetricTypeFactory} from "@sascha_kirstein/aclipp-models"

const initialState = {
    metricTypeAction: null,
    metricTypeError: null,
    data: MetricTypeFactory.ACLIPP_METRIC_TYPE,
    ordered: Object.values(MetricTypeFactory.ACLIPP_METRIC_TYPE), //with immer we can't sort array from states
    keys: Object.keys(MetricTypeFactory.ACLIPP_METRIC_TYPE),
};

const metricTypeSlice = createSlice({
    name: 'metricType',
    initialState,
    reducers: {
        loadMetricType: (state, {payload} ) => {
            state.data = payload;
            state.ordered = Object.values(payload);
            state.keys = Object.keys(payload);
        }
    }
});

export const metricTypeAction = metricTypeSlice.actions;
export default metricTypeSlice.reducer;