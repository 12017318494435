import {Clipping} from '@sascha_kirstein/aclipp-models'
import firebase from '../../config/fbConfig';

export const ONLINE_ENDPOINT = "/api/v1/clipping/";
export const TEST_ENDPOINT =  'http://127.0.0.1:5001/dev-aclipp/us-central1/apiEndpoint' +  ONLINE_ENDPOINT;

/**
 * fetch data from online api and enrich clipping object
 * @param {String} agencyID
 * @param {object} metricType
 */
export const getAllClippingAPI = async (agencyID, metricType) => {
    try{
        const ID_Token = await firebase.auth().currentUser.getIdToken();
        const _url = window.location.hostname === 'localhost' ? TEST_ENDPOINT : ONLINE_ENDPOINT;
        const res = await fetch(_url + agencyID, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${ID_Token}`,
                'Content-Type': 'application/json; charset=utf-8',
            },
            redirect: 'follow'
        })

        const {url} = await res.json()
        const data = await fetch(url)
        const clippingMap = await data.json()
        return Clipping.fromFirestore(clippingMap, metricType);
    }
    catch (e) {
        console.log(e)
        return {}
    }
}
