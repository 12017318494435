import {Agency, Analytic, Clipping, MetricType} from "@sascha_kirstein/aclipp-models";
import {analyticAction} from "../reducers/analyticReducer";
import firebase from "firebase/compat/app";

export const loadAnalyticFromFS = (fsAnalytic) => {

    return (dispatch, getState, getFirebase) => {

        const _fsAnalytic = fsAnalytic ? fsAnalytic :
            getState().firestore.data[Analytic.FS_COLLECTION_NAME]; //TODO change

        const analytic = getState().analytic.current;

        try {
            analytic.loadDataFromFS(_fsAnalytic)
        } catch (err) {
            console.error('cam`t load analytic from storage', err);
        }
    };
};
//TODO I would move it directly to component
export const setCurrentAnalytic = (analytic) => {

    return (dispatch, getState, getFirebase) => {
            dispatch(analyticAction.setCurrentAnalytic( analytic ));
    };
};


export const loadAnalyticFromClippings = (clipping) => {

    return async (dispatch, getState, getFirebase) => {
        const agencyID = getState().agency.agencyID;
        const metricType = getState().metricType.data;
        const analytic = getState().analytic.current;

        const fs = getFirebase().firestore();
        const clippingRef = fs.collection(Agency.FS_COLLECTION_NAME)
            .doc(agencyID)
            .collection(Clipping.FS_COLLECTION_NAME)


        if(!clipping){
            // Fetch data
            const querySnapshot = await clippingRef.get()
            const data = {};

            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                data[doc.id] = doc.data()
            });

            clipping = Object.values(Clipping.fromFirestore(data, metricType))
        }

        if (clipping.length > 0) {
            analytic.importClipping(clipping, true)
            // write to database
            const data = analytic.exportToFirestore()
            Object.keys(data).forEach(year => {
                fs.collection(Agency.FS_COLLECTION_NAME)
                    .doc(agencyID)
                    .collection(Analytic.FS_COLLECTION_NAME)
                    .doc(year)
                    .set(data[year])
            })
        }
    };
};

/**
 * update the fs analytics object.
 * @param {Clipping} curClipping State.
 * @param {Clipping} [prevClipping] State, if exists.
 */
export const updateAnalytic = (curClipping, prevClipping) =>
    async (dispatch, getState, getFirebase) => {
        try {
            // increment and decrement of the analytic object might be needed
            if (prevClipping) {
                await removeFromAnalytic(prevClipping, getState, getFirebase);
                await addToAnalytic(curClipping, getState, getFirebase);

            } else {
                await addToAnalytic(curClipping, getState, getFirebase)
            }
        } catch
            (err) {
                console.log( err );
        }
    };


export const removeAnalytic = (clipping) =>
    async (dispatch, getState, getFirebase) => {
        try {
            await removeFromAnalytic(clipping, getState, getFirebase)
        } catch (err) {
            console.log( err );
        }
    }


const addToAnalytic = async (clipping, getState, getFirebase) => {

    const fs = getFirebase().firestore();
    const analytic = getState().analytic.current;
    const agencyID = getState().agency.agencyID;
    const year = "" + clipping.publishedDate.year();
    const day = "" + clipping.publishedDate.dayOfYear();
    const fieldString = `${clipping.channel}.${clipping.client}.${day}`;

    const initDocument = () => {
        const initDoc = {}
        Object.keys(Clipping.CHANNEL).forEach((key) => {
            initDoc[key] = {}
        })
        // First Clipping with this publishedDate year -> create document
        return fs.collection(Agency.FS_COLLECTION_NAME)
            .doc(agencyID)
            .collection(Analytic.FS_COLLECTION_NAME)
            .doc(year)
            .set(initDoc)
    }

    const doc = {
        [`${fieldString}.count`]: fs.FieldValue.increment(1)
    }
    const count = analytic.data[clipping.channel]?.[clipping.client]?.[year]?.[day]?.count;
    // each metric gets set
    Object.values(clipping.metric).forEach((metric) => {
        if (!Number.isNaN(metric.value)) {
            switch (metric.metricType.summaryType) {
                case MetricType.SUMMARY_TYPE.AVERAGE:
                    let curValue = analytic.data[clipping.channel]?.[clipping.client]?.[year]?.[day]?.[metric.id]
                    if (curValue && count) {
                        const avg = (curValue * count + metric.value) / (count + 1)
                        doc[`${fieldString}.${metric.id}`] = fs.FieldValue.increment(avg - curValue)

                    } else {
                        doc[`${fieldString}.${metric.id}`] = fs.FieldValue.increment(metric.value)
                    }
                    break;
                case MetricType.SUMMARY_TYPE.SUM:
                    doc[`${fieldString}.${metric.id}`] = fs.FieldValue.increment(metric.value)
                    break;
                default:
            }
        }
    })

    if (!analytic.hasYear(year)) await initDocument()

    return fs.collection(Agency.FS_COLLECTION_NAME)
        .doc(agencyID)
        .collection(Analytic.FS_COLLECTION_NAME)
        .doc(year)
        .update(doc)

}

const removeFromAnalytic = async (clipping, getState, getFirebase) => {
    const fs = getFirebase().firestore();
    const analytic = getState().analytic.current;
    const agencyID = getState().agency.agencyID;
    const year = "" + clipping.publishedDate.year();
    const day = "" + clipping.publishedDate.dayOfYear();
    const fieldString = `${clipping.channel}.${clipping.client}.${day}`;

    const count = analytic.data[clipping.channel]?.[clipping.client]?.[year]?.[day]?.count;

    // Is there already something in the analytic object?
    if (count) {
        const doc = {}
        //the whole day can be removed
        if (count <= 1) {
            doc[`${fieldString}`] = fs.FieldValue.delete()
        } else {
            doc[`${fieldString}.count`] = fs.FieldValue.increment(-1)
            // each metric gets adapted
            Object.values(clipping.metric).forEach((metric) => {
                if (!Number.isNaN(metric.value)) {
                    const curValue = analytic.data[clipping.channel]?.[clipping.client]?.[year]?.[day]?.[metric.id]
                    if (curValue) {
                        switch (metric.metricType.summaryType) {
                            case MetricType.SUMMARY_TYPE.AVERAGE:
                                const avg = (curValue * count - metric.value) / (count - 1)
                                doc[`${fieldString}.${metric.id}`] = fs.FieldValue.increment(avg - curValue)
                                break;
                            case MetricType.SUMMARY_TYPE.SUM:
                                doc[`${fieldString}.${metric.id}`] = fs.FieldValue.increment(-(metric.value))
                                break;
                            default:
                        }
                    }
                }
            })
        }
        return fs.collection(Agency.FS_COLLECTION_NAME)
            .doc(agencyID)
            .collection(Analytic.FS_COLLECTION_NAME)
            .doc(year)
            .update(doc)
    }
}