import {Agency, Report} from "@sascha_kirstein/aclipp-models";
import {reportAction} from "../reducers/reportReducer";


export const loadReportFromFS = (fsReport) => {
    return (dispatch, getState, getFirebase) => {

        const _fsReport = fsReport ? fsReport : getState().firestore.data[Report.FS_COLLECTION_NAME]; //TODO change firestore.data

        try {
            dispatch( reportAction.loadReport(_fsReport) );
        } catch (error) {
            dispatch( reportAction.reportError( {error, path: 'Load'} ) );
        }
    }
}; // transforms report documents from firestore to Report Object and add them to state.report.data / ordered / keys

export const updateOrCreateReport = (report) => {

    if (report.id) {
        return (dispatch, getState, getFirebase) => {
            const agencyID = getState().agency.agencyID;
            const firebase = getFirebase();
            const firestore = firebase.firestore();

            return firestore.collection(Agency.FS_COLLECTION_NAME)
                .doc(agencyID)
                .collection(Report.FS_COLLECTION_NAME)
                .update(report.id)
        }
    } else {
        return (dispatch, getState, getFirebase) => {
            const agencyID = getState().agency.agencyID;
            const firebase = getFirebase();
            const firestore = firebase.firestore();

            return firestore.collection(Agency.FS_COLLECTION_NAME)
                .doc(agencyID)
                .collection(Report.FS_COLLECTION_NAME)
                .add({})
                .then((docRef) => {
                    report.id = docRef.id;
                })
        }
    }
}; // if the report already have a report id - function will perform an update otherwise create a report document

export const setCurrentReport = (report) => {
    return (dispatch, getState, getFirebase) => {
        const agency = getState().agency.signedInAgency;

        const user = getState().user.current;

        // Try to preset values
        try{
            report.company = report.company || agency.name;
            report.agencyLogoURL = report.agencyLogoURL || agency.logoURL;
            report.author = report.author ||  user.name;
        }
        catch (e) {
            console.error(e)
        }
        return dispatch( reportAction.setReportSuccess({current: report}) );
    }
}; // set the state.report.current

export const setCurrentReportFromID = (reportID) => {
    return (dispatch, getState, getFirebase) => {

        return dispatch( reportAction.setReportSuccess(reportID) )
    }
}; // set the state.report.current either from state.report.data (if id is included) otherwise load the report from firestore

export const deleteReport = (reportID) => {
    return (dispatch, getState, getFirebase) => {
        const agencyID = getState().agency.agencyID;
        const firestore = getFirebase().firestore();

        return firestore.collection(Agency.FS_COLLECTION_NAME)
            .doc(agencyID)
            .collection(Report.FS_COLLECTION_NAME)
            .doc(reportID)
            .delete()
    }
};