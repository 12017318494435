import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import firebase, {app} from '../config/fbConfig'; //TODO change to firebase v9
import {getAuth} from 'firebase/auth'
import {authAction} from "./reducers/authReducer";
/**
 * Custom hook for dispatching storage on user change
 */

export const useUserAuth = () => {
    const auth = firebase.auth();
    const dispatch = useDispatch();

    useEffect(() => {
        return auth.onAuthStateChanged(async (userObj) => {
            if (userObj) {
                const authedUser = userObj.multiFactor.user; // auth object with data about user and token
                const {claims} = await userObj.getIdTokenResult();

                dispatch( authAction.login( { claims, authedUser} ) );
            } else {
                //user = null
                dispatch( authAction.logout())
            }
        });
    }, []);

    return null;
};
