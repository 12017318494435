import React from 'react'
import {ReactSVG} from 'react-svg'

export const ClientsIcon = props => (
    <ReactSVG svgstyle={{width: 14, height: 16}} src="/icons/client.svg"/>
);

export const ClippingsIcon = props => (
    <ReactSVG svgstyle={{width: 19, height: 21}} src="/icons/clipping.svg"/>
);

export const DashboardIcon = props => (
    <ReactSVG svgstyle={{width: 16, height: 16}} src="/icons/dashboard.svg"/>
);

export const ReportIcon = props => (
    <ReactSVG svgstyle={{width: 17, height: 21}} src="/icons/report.svg"/>
);

export const SettingsIcon = props => (
    <ReactSVG svgstyle={{width: 16, height: 18}} src="/icons/setting.svg"/>
);

export const SettingCircleIcon = props => (
    <ReactSVG svgstyle={{width: 16, height: 18}} src="/icons/setting-circle.svg"/>
);

export const ClippingCircle = props => (
    <ReactSVG svgstyle={{width: 38, height: 38}} src="/icons/clipping-circle.svg"/>
);

export const ReportCircle = props => (
    <ReactSVG svgstyle={{width: 38, height: 38}} src="/icons/report-circle.svg"/>
);

export const EmailControlIcon = props => (
    <ReactSVG svgstyle={{width: 460, height: 309}} src="/icons/email-logincontrol.svg"/>
);

export const DropIconBig = props => (
    <ReactSVG beforeInjection={svg => {
        props.fill && svg.setAttribute('fill', props.fill)
        props.opacity && svg.setAttribute('opacity', props.opacity)
    }}
              src="/icons/dropiconbig.svg"/>
);

export const DropIconSmall = props => (
    <ReactSVG
        beforeInjection={svg => {
            svg.setAttribute('style', props.style)
            props.fill && svg.setAttribute('fill', props.fill)
        }}
        src="/icons/dropiconsmall.svg"
    />
);


export const DropIconCircle = props => (
    <ReactSVG
        beforeInjection={svg => {
            svg.setAttribute('style', 'width: 50px; height: 50px')
        }} src="/icons/dropIconCircle.svg"/>
);


export const UploadIcon = props => (
    <ReactSVG svgstyle={{width: 20, height: 20}} src="/icons/upload.svg"/>
);

export const SendIcon = props => (
    <ReactSVG svgstyle={{width: 20, height: 20}} src="/icons/send.svg"/>
);

export const SendCircleIcon = props => (
    <ReactSVG svgstyle={{width: 20, height: 20}} src="/icons/send-circle.svg"/>
);

export const AddIcon = props => (
    <ReactSVG
        beforeInjection={svg => {
            svg.setAttribute('style', 'width: 44px; height: 44px')
        }}
        src="/icons/add.svg"/>
);

export const PowerIcon = props => (
    <ReactSVG src="/icons/power.svg"/>
);

export const VisibleIcon = props => (
    <ReactSVG
        beforeInjection={svg => {
            svg.setAttribute('style', 'padding-top: 6px')
        }}
        src="/icons/visible.svg"/>
);

export const EditIcon = props => (
    <ReactSVG src="/icons/edit.svg"/>
);

export const DownLoadIcon = props => (
    <ReactSVG
        beforeInjection={svg => {
            svg.setAttribute('style', 'padding-top: 6px')
        }}
        src="/icons/visible.svg"/>
);

export const TagsIcon = props => (
    <ReactSVG src="/icons/tag-circle.svg"/>
);

export const TagIcon = props => (
    <ReactSVG src="/icons/tag-circle.svg"/>
);

export const ChannelIcon = props => (
    <ReactSVG src="/icons/channel.svg"/>
);

export const TagSelectedIcon = props => (
    <ReactSVG src="/icons/tagSelected.svg"/>
);

export const ChannelSelectedIcon = props => (
    <ReactSVG src="/icons/channelSelected.svg"/>
);

export const LogoutCircleIcon = props => (
    <ReactSVG src="/icons/logout-circle.svg"/>
);

export const DropdownIcon = props => (
    <ReactSVG src="/icons/dropdownIcon.svg"/>
);

export const NumberIcon = props => (
    <ReactSVG src="/icons/numberIcon.svg"/>
);

export const ShortTextIcon = props => (
    <ReactSVG src="/icons/shortTextIcon.svg"/>
);

export const SliderIcon = props => (
    <ReactSVG src="/icons/sliderIcon.svg"/>
);

export const SwitchIcon = props => (
    <ReactSVG src="/icons/switchIcon.svg"/>
);

export const WarningIcon = props => (
    <ReactSVG src="/icons/warning.svg"/>
);

export const NewIcon = props => (
    <ReactSVG src="/icons/new.svg"/>
);

export const PageIcon = props => (
    <ReactSVG src="/icons/report/page.svg"/>
);

export const TextIcon = props => (
    <ReactSVG src="/icons/report/text.svg"/>
);

export const ColorPickerIcon = props => (
    <ReactSVG src="/icons/color-picker.svg"/>
);

export const HashtagIcon = props => (
    <ReactSVG src="/icons/hashtag.svg"/>
);

export const CalculatorIcon = props => (
    <ReactSVG src="/icons/calculator.svg"/>
);

export const FormulaIcon = props => (
    <ReactSVG src="/icons/formula.svg"/>
);

export const Arrow2LeftIcon = props => (
    <ReactSVG src="/icons/arrow2left.svg"/>
);

export const PlusIcon = props => (
    <ReactSVG beforeInjection={svg => {
        props.style && svg.setAttribute('style', props.style)
        props.fill && svg.setAttribute('fill', props.fill)
    }}
              src="/icons/plus.svg"/>
);

export const BrandIcon = props => (
    <ReactSVG
        beforeInjection={svg => {
            props.style && svg.setAttribute('style', props.style)
        }}
        src="/icons/heart.svg"/>
);