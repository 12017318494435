import React from 'react';
import {connect} from 'react-redux'
import {updateQuery} from "../store/actions/clippingActions";
import firebase from "../config/fbConfig";

const analytics = firebase.analytics();

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {hasError: false, error: null};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true, error};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.info({description: error, fatal: true})
        this.setState({...this.state, error, errorInfo})
        analytics.logEvent('exception', {description: error, fatal: true})
    }

    render() {
        if (this.state.hasError) {

            // Try to reset
            if (!this.state.timer) {
                try {
                    localStorage.clear();
                    indexedDB.deleteDatabase('aclipp')
                } catch (e) {
                }
                const timer = setTimeout(() => {
                    this.setState({hasError: false})
                }, 1000)
                this.setState({timer, ...this.state})
            }

            // You can render any custom fallback UI
            return (
                <div>
                    <h1>Ups.</h1>
                    <div>
                        {this.state.error.name}
                    </div>
                    <div>
                        {'' + this.state.error.message}
                    </div>
                    <div>
                        {'' + this.state.error.stack}
                    </div>
                </div>
            )
        }

        return this.props.children;
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        updateQuery: query => {
            dispatch(updateQuery(query, 'ErrorBoundary'))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);