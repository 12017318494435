import {useSelector} from "react-redux";
import {useEffect, useState} from "react";


export const APP_STATE = {
    BOOT: 'BOOT',
    LOGGED_OUT: 'LOGGED_OUT',
    INIT: 'INIT',
    AGENCY_FIRST_SETUP: 'AGENCY_FIRST_SETUP',
    NORMAL: 'NORMAL'
}

export default function useAppState() {
    const {isLoaded: authIsLoaded, isEmpty, userID} = useSelector(state => state.auth);
    const {isLoaded: agencyIsLoaded, signedInAgency, agencyID} = useSelector(state => state.agency);

    const [appState, setAppState] = useState(APP_STATE.BOOT);

    if (authIsLoaded) {
        // if auth.authedUser.uid is not a string, the user is not logged in
        if (isEmpty && appState !== APP_STATE.LOGGED_OUT) {
            setAppState(APP_STATE.LOGGED_OUT);
        }
        // user is logged in
        if (typeof userID === 'string' && [APP_STATE.BOOT, APP_STATE.LOGGED_OUT].includes(appState)) {
            setAppState(APP_STATE.INIT);
        }
    }

    useEffect(() => {
        if (appState !== APP_STATE.NORMAL && agencyIsLoaded && agencyID !== '') {
            if (signedInAgency && appState !== APP_STATE.NORMAL) {
                setAppState(APP_STATE.NORMAL)
            }

            if (!signedInAgency && appState !== APP_STATE.AGENCY_FIRST_SETUP) {
                setAppState(APP_STATE.AGENCY_FIRST_SETUP)
            }
        }
    }, [appState, agencyID, signedInAgency, agencyIsLoaded]);

    return appState;
}