import {Dashboard} from "@sascha_kirstein/aclipp-models";
import {createSlice} from "@reduxjs/toolkit";

const dashboard = new Dashboard({
    name: 'Dashboard',
    element: Dashboard.dashboardElementBuilder(),
});

const initialState = {
    action: '',
    dashboard: dashboard,
    data: {},
    ordered: [],
    keys: [],
    isLoaded: false,
    showTrend: true,
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        loadDashboard: ( state, {payload} ) => {
            const ordered = Object.values(payload)
            state.dashboard = ordered.length > 0 ? ordered[0] : dashboard;
            state.data = payload;
            state.ordered = ordered;
            state.keys = Object.keys(payload);
            state.isLoaded = true;
        },
        setDashboard: ( state, {payload} ) => {
            state.action = 'SET_DASHBOARD' + Date.now();
            state.dashboard = payload;
        },
        setShowTrend: ( state, {payload} ) => {
            state.showTrend = payload;
        },
    }
});

export const dashboardAction = dashboardSlice.actions;
export default dashboardSlice.reducer;
