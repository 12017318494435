import React, {lazy, Suspense} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import {Aclipp} from '@sascha_kirstein/aclipp-models'
import aclippTheme from './config/theme.js'
//Redux + Firebase
import {useSelector} from 'react-redux'
import {useUserAuth} from "./store/useUserAuth";
// material-ui
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';

// own components
import Loading from "./pages/Loading";
import ErrorBoundary from './pages/ErrorBoundary';
import MenuDrawer from "./components/layout/MenuDrawer";
import TopNav from "./components/layout/TopNav";
import MainArea from "./components/layout/MainArea";
import useAppState, {APP_STATE} from "./useAppState";

const FSConnect = lazy(() => import("./store/FSConnect"))
const DataAcquisition = lazy(() => import("./DataAcquisition"))

const LogIn = lazy(() => import("./pages/auth/LogIn"))
const SignUp = lazy(() => import("./pages/auth/SignUp"))
const Recovery = lazy(() => import("./pages/auth/Recovery"))
const UserManagement = lazy(() => import("./pages/auth/UserManagment"))
const CreateAgency = lazy(() => import("./pages/auth/CreateAgency"))
const UserInvite = lazy(() => import("./pages/auth/UserInvite"))
const PublicDashboard = lazy(() => import("./pages/dashboard/PublicDashboard"));

const PageTitle = lazy(() => import("./PageTitle"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"))
const DashboardCreateOrEdit = lazy(() => import("./pages/dashboard/DashboardCreateOrEdit"))
const Clippings = lazy(() => import("./pages/clipping/Clippings"))
const ClippingCreateOrEdit = lazy(() => import("./pages/clipping/ClippingCreateOrEdit"))
const ClippingImport = lazy(() => import("./pages/clipping/ClippingImport"))
const ReportCreateOrEdit = lazy(() => import("./pages/report/ReportCreateOrEdit"))

const Clients = lazy(() => import("./pages/client/Clients"))
const ClientEdit = lazy(() => import("./pages/client/ClientEdit"))
const Settings = lazy(() => import("./pages/setting/Settings"))
const SuperUser = lazy(() => import("./pages/superuser/SuperUser"))

function App() {
    useUserAuth();
    const appState = useAppState();

    if (appState === APP_STATE.BOOT)
        return (
            <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={aclippTheme}>
                    <Loading/>
                </MuiThemeProvider>
            </StyledEngineProvider>
        );

    if (appState === APP_STATE.LOGGED_OUT)
        return (
            <BrowserRouter>
                <StyledEngineProvider injectFirst>
                    <MuiThemeProvider theme={aclippTheme}>
                        <Suspense fallback={<Loading/>}>
                            <PageTitle/>
                            <DataAcquisition/>
                            <Routes>
                                <Route path={Aclipp.ROUTES.USER_INVITE + ':id'} element={<UserInvite/>}/>
                                <Route path={Aclipp.ROUTES.LOGIN} element={<LogIn/>}/>
                                <Route path={Aclipp.ROUTES.SIGNUP} element={<SignUp/>}/>
                                <Route path={Aclipp.ROUTES.USER_MANAGEMENT} element={<UserManagement/>}/>
                                <Route path={Aclipp.ROUTES.RECOVERY} element={<Recovery/>}/>
                                <Route path={Aclipp.ROUTES.PUBLIC_DASHBOARD} element={<PublicDashboard/>}/>
                                <Route path='*' element={<Navigate to={Aclipp.ROUTES.LOGIN} replace/>}/>
                            </Routes>
                        </Suspense>
                    </MuiThemeProvider>
                </StyledEngineProvider>
            </BrowserRouter>
        );

    return (
        <BrowserRouter>
            <StyledEngineProvider injectFirst>
                <MuiThemeProvider theme={aclippTheme}>
                    <Suspense fallback={<Loading/>}>
                        <PageTitle/>
                        <TopNav/>
                        <MenuDrawer/>
                        <MainArea>
                            <ErrorBoundary>
                                <DataAcquisition/>
                                <FSConnect/>

                                {appState === APP_STATE.AGENCY_FIRST_SETUP &&
                                <Routes>
                                    <Route path={Aclipp.ROUTES.NEW_AGENCY} element={<CreateAgency/>}/>
                                    <Route path={Aclipp.ROUTES.USER_MANAGEMENT} element={<UserManagement/>}/>
                                    <Route path='*' element={<Navigate to={Aclipp.ROUTES.NEW_AGENCY} replace/>}/>
                                </Routes>
                                }

                                {appState === APP_STATE.NORMAL &&
                                <Routes>
                                    <Route exact path='/' element={<Dashboard/>}/>

                                    <Route path={Aclipp.ROUTES.DASHBOARD_EDIT + ':id'} element={<DashboardCreateOrEdit/>}/>
                                    <Route path={Aclipp.ROUTES.DASHBOARD} element={<Dashboard/>}/>
                                    <Route path={Aclipp.ROUTES.PUBLIC_DASHBOARD} element={<PublicDashboard/>}/>

                                    <Route path={Aclipp.ROUTES.CLIENT_CONNECTOR + ':id'} element={<ClientEdit/>}/>
                                    <Route path={Aclipp.ROUTES.CLIENT_TAG + ':id'} element={<ClientEdit/>}/>
                                    <Route path={Aclipp.ROUTES.CLIENT_EDIT + ':id'} element={<ClientEdit/>}/>
                                    <Route path={Aclipp.ROUTES.CLIENT} element={<Clients/>}/>

                                    <Route path={Aclipp.ROUTES.CLIPPING_IMPORT} element={<ClippingImport/>}/>
                                    <Route path={Aclipp.ROUTES.CLIPPING_EDIT + ':id'}
                                           element={<ClippingCreateOrEdit/>}/>
                                    <Route path={Aclipp.ROUTES.CLIPPING_EDIT} element={<ClippingCreateOrEdit/>}/>
                                    <Route path={Aclipp.ROUTES.CLIPPING} element={<Clippings/>}/>

                                    <Route path={Aclipp.ROUTES.REPORT_EDIT} element={<ReportCreateOrEdit/>}/>
                                    <Route path={Aclipp.ROUTES.REPORT} element={<ReportCreateOrEdit/>}/>


                                    <Route path={Aclipp.ROUTES.SETTING} element={<Settings/>}/>

                                    <Route path={Aclipp.ROUTES.SUPERUSER + '/*'} element={<AuthorizedSuperUser/>}/>
                                    <Route path={Aclipp.ROUTES.USER_MANAGEMENT} element={<UserManagement/>}/>
                                    <Route path='*' element={<Navigate to={Aclipp.ROUTES.DASHBOARD} replace/>}/>
                                </Routes>
                                }
                            </ErrorBoundary>
                        </MainArea>
                    </Suspense>
                </MuiThemeProvider>
            </StyledEngineProvider>
        </BrowserRouter>
    );
}


function AuthorizedSuperUser(props) {
    const {superUser} = useSelector(state => state.auth);
    return superUser ? <SuperUser/> : <Navigate to='/' replace />;
}

export default App;