import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/analytics'
import 'firebase/compat/functions';
import 'firebase/compat/storage';

import {initializeApp} from 'firebase/app';

const ENVIRONMENT = {
    DEV: 'DEV',
    LIVE: 'LIVE'
}
const fbConfigs = {
    [ENVIRONMENT.DEV]: {
        apiKey: "AIzaSyDGJWxFJAHeIVoLAB472FeCwQhz-ksKRq4",
        authDomain: "dev-aclipp.firebaseapp.com",
        databaseURL: "https://dev-aclipp.firebaseio.com",
        projectId: "dev-aclipp",
        storageBucket: "dev-aclipp.appspot.com",
        messagingSenderId: "1040746589868",
        appId: "1:1040746589868:web:a14562caf43792fc53f909",
        measurementId: "G-LCDSPPHX77"
    },
    [ENVIRONMENT.LIVE]: {
        apiKey: "AIzaSyCFpIvzi_gOzLjqZ9jJBPcpUU6gWj6pFPI",
        authDomain: "app-aclipp.firebaseapp.com",
        databaseURL: "https://app-aclipp.firebaseio.com",
        projectId: "app-aclipp",
        storageBucket: "app-aclipp.appspot.com",
        messagingSenderId: "422363431693",
        appId: "1:422363431693:web:5bc0677b3e05ec8280a644",
        measurementId: "G-JYX9XHHWG9"
    }
}

// Auto Environment SetUp based on hostname
let curEnvironment = ENVIRONMENT.DEV
try {
    if (window.location.hostname === 'app.aclipp.com') {
        curEnvironment = ENVIRONMENT.LIVE
    }
} catch (e) {
    console.error('Could not determine Environment. Fallback is DEV')
}

console.log({MODE: process.env.NODE_ENV});//TODO
console.info(`ENV: ${curEnvironment}`)
firebase.initializeApp(fbConfigs[curEnvironment]);

firebase.firestore();
firebase.storage();
firebase.analytics();

export const app = initializeApp(fbConfigs[curEnvironment]); //we are not using it now
export default firebase;

