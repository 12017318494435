const TASK_TYPE = {
    QUERY: 'QUERY',
    FUZZY: 'FUZZY',
    LUNR: 'LUNR',
    DIRECT_SEARCH: 'DIRECT_SEARCH'
}

const TASK_FALLBACK = {
    QUERY: {data: [], dimension: []},
    FUZZY: [],
    LUNR: [],
    DIRECT_SEARCH: [],
}

const genHash = (length = 8) => {
    const chars = [..."abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"];
    let result = "";

    try {
        let array = new Uint8Array(length);
        window.crypto.getRandomValues(array);
        for (let i = 0; i < length; i++) {
            result += chars[(array[i] % chars.length)]
        }
    } catch (e) {
        result = "";
        for (let i = 0; i < length; i++) {
            const randomNr = Math.floor(Math.random() * chars.length);
            result += chars[randomNr]
        }
    }

    return result
}

export class Request {
    constructor({
                    key = '',
                    type = TASK_TYPE.QUERY,
                    fallback = TASK_FALLBACK.QUERY,
                    body,
                }) {
        this.key = key;
        this.type = type;
        this.fallback = fallback;
        this.body = body;
    }

    static get TASK_TYPE() {
        return TASK_TYPE;
    }

    static get TASK_FALLBACK() {
        return TASK_FALLBACK;
    }
}

export class Task {
    constructor({
                    id = genHash(),
                    request = new Request({}),
                    collection = '',
                }) {
        this.id = id;
        this.request = request;
        this.collection = collection;
    }
}

const WORKER_STATUS = {
    RUNNING: 'RUNNING',
    IDLE: 'IDLE',
    ERROR: 'ERROR'
}

export class TaskWorker {
    constructor({
                    id = 0,
                    status = WORKER_STATUS.IDLE,
                    timeout = 15,
                }) {
        this.id = id;
        this.status = status;
        this.timeout = timeout;
        this.worker = new Worker('/worker/UniversalWorker.js')
    }

    run(task) {
        return new Promise((resolve, reject) => {
            this.status = WORKER_STATUS.RUNNING;
            this.worker.postMessage(task);

            const timer = setTimeout(async () => {
                this.worker.terminate();
                this.worker = new Worker('/worker/UniversalWorker.js')
                this.status = WORKER_STATUS.IDLE
                reject('WORKER TIMEOUT');

            }, this.timeout * 1000) // sec

            this.worker.addEventListener("message", (e) => {
                clearTimeout(timer);
                this.status = WORKER_STATUS.IDLE;
                // if worker sends message back, worker is ready again for another query
                resolve(e.data);
            })
        })
    }
}

export class WorkerPool {
    constructor(workerCount = 4) {
        this.worker = [...Array(workerCount).keys()].map((i) => new TaskWorker({id: i}));
        this.task = [];
    }

    addTask(task){
        return new Promise((resolve, reject) => {
            this.task.push({task, resolve, reject})
            this.solveTask();
        })

    }

    removeTask(task){
        this.task = this.task.filter((t) => t.task.id !== task.id)
    }

    solveTask(){
        const idleWorker = this.worker.find((w) => w.status === WORKER_STATUS.IDLE)
        if(idleWorker && this.task.length > 0){
            const {task, resolve, reject} = this.task.pop();
            idleWorker.run(task).then((res) => {
                resolve(res)
                // solve the next task
                this.solveTask()
            }).catch(reject)
        }
    }

    async flush(collection){
        const task = new Task({
            request: new Request({
                key: 'flush',
                type: 'FLUSH',
                fallback: [],
                body: {}
            }),
            collection
        })

        if(this.worker.every((w) => w.status === "IDLE")){
            return Promise.all(this.worker.map((w) => w.run(task)))
        } else {
            await this.timeout(500);
            return this.flush(collection)
        }
    }

     timeout = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}

