import React, {Suspense, useEffect, useRef} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {useLocation} from "react-router-dom";

import {DRAWER_WIDTH, DRAWER_WIDTH_EXTENDED} from "./MenuDrawer";
import {useSelector} from "react-redux";
import UploadSnackBar from "./UploadSnackBar";
import Scrollbars from "react-custom-scrollbars-2";

const navHeight = 64;

export default function MainArea(props) {

    const classes = useStyles();
    const ScrollRef = useRef();
    const location = useLocation();
    const menuIsExtended = useSelector(state => state.app.menuExtended);
    const menuWidth = menuIsExtended ? DRAWER_WIDTH_EXTENDED : DRAWER_WIDTH

    // Scroll to top if location changes
    useEffect(() => {
        ScrollRef.current && ScrollRef.current.scrollTop(0)
    }, [location])


    return (
        <div
            className={classes.root}
        >
            <main
                className={classes.main}
                style={{paddingLeft: menuWidth}}
            >
                <UploadSnackBar/>
                <Scrollbars
                    ref={ScrollRef}
                    // This will activate auto hide
                    autoHide
                    // Hide delay in ms
                    autoHideTimeout={4000}
                    // Duration for hide animation in ms.
                    autoHideDuration={1000}>
                    <Suspense fallback={<div/>}>
                        <div className={classes.paper}>
                            {props.children}
                        </div>
                    </Suspense>
                </Scrollbars>
            </main>
        </div>

    )


}

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        backgroundColor: '#F0F0F7',
        top: navHeight,
        height: "100%",
        width: '100%',
    },
    main: {
        display: 'block', // Fix IE 11 issue.
        marginLeft: 0,
        marginRight: 0,
        paddingBottom: 0,
        paddingTop: 0,
        height: '100%',
        transition: theme.transitions.create(['padding'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    paper: {
        backgroundColor: '#F0F0F7',
        marginTop: 0,
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0px 80px 120px 80px',
        "@media screen and (max-width:1440px)": {
            paddingLeft: 20,
            paddingRight: 20
        }
    },

}));

