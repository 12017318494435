import {appAction} from "../reducers/appReducer";

export const setConfig = (config = {}) =>
    (dispatch, getState, getFirebase) => {
        if(config){
            const userID = getState().auth.uid;
            persistConfig(config, userID);

            appAction.setConfigSuccess ({config} );
        }
    };

export const configName = (userID) => {
    return `FSConfig[${userID.substring(0,6)}]`
}

export const loadConfig = () => {
    return (dispatch, getState, getFirebase) => {
        const {userID} = getState().auth
        try {
            const config = JSON.parse(localStorage.getItem(configName(userID))) || {};
            dispatch( appAction.loadConfigFromLocalStorage({config}) );
        } catch (e) {
            console.log('Could not load app config')
        }
    }
}

const persistConfig = (config, userID) => {
    if(userID){
        try {
            localStorage.setItem(configName(userID), JSON.stringify(config))
        } catch (e) {
            console.log('Could not persist app config')
        }
    }
}

export const setMenuExtended = (isExtended) =>
    (dispatch, getState, getFirebase) =>
        dispatch(appAction.setMenuExtended({isExtended}) );

