import {Aclipp, Agency, Clipping, MetricType, User} from "@sascha_kirstein/aclipp-models";
import {clippingAction} from "../reducers/clippingReducer";
import {UploadTask} from "../ClippingFileUploader";
import moment from "moment"
import {fullfillGoal, updateUser} from "./userActions";
import {removeAnalytic, updateAnalytic} from "./analyticActions";
import {getAllClippingAPI, ONLINE_ENDPOINT, TEST_ENDPOINT} from "../../api/clipping/GetAllClipping";
import localforage from "localforage";

/**
 * load Clippings from Firestore, add Clip URLs and update the redux store
 * @param {Object} fsClipping object.
 */
export const loadClippingFromFS = (fsClipping) =>
    (dispatch, getState, getFirebase) => {
        //const _fsClipping = fsClipping ? fsClipping : getState().firestore.data[Clipping.FS_COLLECTION_NAME];
        const {data: clippingData, query} = getState().clipping;
        const _metricType = getState()[MetricType.FS_COLLECTION_NAME].data;

        const _agencyID = getState().agency.agencyID;
        const storage = getFirebase().storage();

        const orderedData = (data, {orderBy, descendingOrder}) => {
            orderBy = orderBy[0] || 'created';
            const ordered = Object.values(data).sort((a, b) => {
                return a[orderBy] - b[orderBy];
            })

            if (descendingOrder) {
                ordered.reverse()
            }

            return ordered;
        };


        try {
            let clippings = Clipping.fromFirestore(fsClipping, _metricType);
            let storageRef = storage.ref(`${Aclipp.FS_AGENCY_PREFIX}/${_agencyID}`);

            let promise = [];

            Object.values(clippings).forEach((clipping) => {

                if (clipping.status === Clipping.STATUS.DRAFT ||
                    clipping.status === Clipping.STATUS.FOR_CONTROL ||
                    clipping.status === Clipping.STATUS.COMPLETE) {

                    const currentClipping = clippingData[clipping.id]

                    clipping.clip.forEach((clip) => {

                        // only load clip urls, if there not already loaded in the past
                        if (currentClipping) {
                            const currentClip = currentClipping.clip.find((c) => c.id === clip.id)

                            if (currentClip && currentClip.imageSource.length > 0) {
                                clip.imageSource = currentClip.imageSource;
                                clip.smallImageSource = currentClip.smallImageSource;
                            }
                        }

                        if (clip.imageSource.length === 0) {
                            promise.push(storageRef
                                .child(clip.imageStorageRef)
                                .getDownloadURL()
                                .then((url) => {
                                    clip.imageSource = url;
                                })
                                .catch((err) => {
                                    clipping.status = Clipping.STATUS.ERROR;
                                }));
                        }

                        if (clip.hasSmallImage && clip.smallImageSource.length === 0) {
                            promise.push(storageRef
                                .child(clip.smallImageStorageRef)
                                .getDownloadURL()
                                .then((url) => {
                                    clip.smallImageSource = url;
                                })
                                .catch((err) => {
                                    clipping.status = Clipping.STATUS.ERROR;
                                }));
                        }
                    })

                }
            });

            Promise.all(promise).then(() => {
                dispatch(clippingAction.loadClipping({data: clippings, ordered: orderedData(clippings, query)}));
            })
        } catch (err) {
            console.error({LOAD_CLIPPING: err});

        }
    };


/**
 * update or create a new clipping document at the firestore and upload files to firestorage.
 * @param {Clipping} clipping object.
 * @param {function} callback object.
 */
export const updateOrCreateClipping = (clipping, callback = () => {
}) => {
    const newClipping = clipping.id.length === 0; // new clipping has no id

    const prepareClipping = (dispatch, getState, getFirebase) => {
        // Set Clipping Metadata
        if (newClipping) {
            dispatch(fullfillGoal(User.GOAL.CLIPPING_CREATED))
            clipping.createBy = clipping.modifyBy = getState().auth.userID;
            clipping.created = clipping.modified = moment();
        } else {
            clipping.modifyBy = getState().auth.userID;
            clipping.modified = moment();
        }

        // remove clips that are marked as deleted before the first upload
        clipping.clip = clipping.clip.filter((clip) => !(clip.deleted && clip.imageFSPath === ''))

        // set clip image paths for all new clips
        clipping.clip.forEach(clip => {
            if (clip.imageFSPath === '')
                clip.imageFSPath = clipping.created.format('YY/MM/') + clipping.client.substring(0, 8);
        })
    }

    const loadFSClipping = async (dispatch, getState, getFirebase) => {
        const agencyID = getState().agency.agencyID;

        if (newClipping) {
            // load clipping from CF
            const ID_Token = await getFirebase().auth().currentUser.getIdToken();
            const url = window.location.hostname === 'localhost' ? TEST_ENDPOINT : ONLINE_ENDPOINT;
            const res = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${ID_Token}`,
                    'Content-Type': 'application/json; charset=utf-8',
                },
                redirect: 'follow',
                body: JSON.stringify({clipping: clipping.toFirestore(), agencyID: agencyID})
            })
            if (!res.ok) {
                throw Error(res.statusText);
            }
            clipping.id = await res.text()

            return clipping
        } else {
            const doc = await getFirebase()
                .firestore()
                .collection(Agency.FS_COLLECTION_NAME)
                .doc(agencyID)
                .collection(Clipping.FS_COLLECTION_NAME)
                .doc(clipping.id)
                .get()

            return (Clipping.fromFirestore({[clipping.id]: doc.data()})[clipping.id])
        }
    }

    const processClip = async (dispatch, getState, getFirebase) => {
        const agencyID = getState().agency.agencyID;
        const storageRef = getFirebase().storage().ref(`${Aclipp.FS_AGENCY_PREFIX}/${agencyID}`);
        const loadedClipping = await loadFSClipping(dispatch, getState, getFirebase)

        if (!newClipping) {

            // determine if there are new clips
            const uploadRequired = clipping.clip
                .some(clip => !clip.deleted && loadedClipping.clip.every(c => c.id !== clip.id))

            if (uploadRequired) {
                // currently set the status to uploading
                await getFirebase()
                    .firestore()
                    .collection(Agency.FS_COLLECTION_NAME)
                    .doc(agencyID)
                    .collection(Clipping.FS_COLLECTION_NAME)
                    .doc(clipping.id)
                    .update({
                        ...clipping.toFirestore(),
                        status: Clipping.STATUS.UPLOADING
                    })

                // upload files that are new
                const uploadTask = new UploadTask(clipping, storageRef, dispatch);
                await getState().clipping.fileUploader.add(uploadTask);
            }
        } else {
            // remove deleted clips before the uploading them
            clipping.clip = clipping.clip.filter((clip) => !clip.deleted);
            const uploadTask = new UploadTask(clipping, storageRef, dispatch);
            await getState().clipping.fileUploader.add(uploadTask);
        }

        // finally upload the clipping
        return getFirebase()
            .firestore()
            .collection(Agency.FS_COLLECTION_NAME)
            .doc(agencyID)
            .collection(Clipping.FS_COLLECTION_NAME)
            .doc(clipping.id)
            .update(clipping.toFirestore())
    }


    return (dispatch, getState, getFirebase) => {
        prepareClipping(dispatch, getState, getFirebase)
        processClip(dispatch, getState, getFirebase)
            .then(() => {
                dispatch(clippingAction.UPDATE_OR_CREATE_SUCCESS);
            })
            .catch((err) => {
                console.error({CLIPPING_UPDATE_OR_CREATE: err})
            })
            .finally(() => callback());

    }
}


/**
 * mark a clipping as deleted in firestore.
 * @param {Clipping} clipping object.
 */
export const deleteClipping = (clipping) => {
    return (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();
        const fs = firebase.firestore();

        const _agencyID = getState().agency.agencyID;


        fs.collection(Agency.FS_COLLECTION_NAME)
            .doc(_agencyID)
            .collection(Clipping.FS_COLLECTION_NAME)
            .doc(clipping.id)
            .update({
                modifyBy: getState().auth.userID,
                modified: moment().toDate(),
                deleted: true
            })
            .then(() => {
                dispatch(removeAnalytic(clipping));
                dispatch(clippingAction.DELETE_CLIPPING_SUCCESS());
            }).catch((err) => {
            console.error({DELETE_CLIPPING_ERROR: err});
        })

    }
};

/**
 * restore a clipping document from firestore.
 * @param {Clipping} clipping object.
 */
export const restoreClipping = (clipping) => {
    return (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();
        const fs = firebase.firestore();

        const _agencyID = getState().agency.agencyID;


        fs.collection(Agency.FS_COLLECTION_NAME)
            .doc(_agencyID)
            .collection(Clipping.FS_COLLECTION_NAME)
            .doc(clipping.id)
            .update({
                modifyBy: getState().auth.userID,
                modified: moment().toDate(),
                deleted: false,
            })
            .then(() => {
                dispatch(updateAnalytic(clipping));
                console.log('RESTORE_CLIPPING')
            }).catch((err) => {
            console.error({RESTORE_CLIPPING_ERROR: err});
        })

    }
};


/**
 * create a new clipping document in firestore and upload files to firestorage.
 * @param {string} currentClippingID object.
 */
export const setCurrentClippingID = (currentClippingID) =>
    (dispatch, getState, getFirebase) =>
        dispatch(clippingAction.setCurrentClipping({currentClippingID}));


export const setCurrentClipping = (clipping = new Clipping(), source) =>
    (dispatch, getState, getFirebase) => {
        try {
            return dispatch(clippingAction.setCurrentClipping({current: clipping}));
        } catch (e) {
            console.error({setCurrentClipping: e})
        }
    }

export const setDateGridState = (state) =>
    (dispatch, getState, getFirebase) => {
        dispatch(clippingAction.setDateGridState({state}));
    }

export const setDateGridDateRange = (dateRange) =>
    (dispatch, getState, getFirebase) => {
        try {
            return dispatch(clippingAction.setDateGridDateRange({dateRange}));
        } catch (e) {
            console.error({setDateGridDateRange: e})
        }
    }

export const updateQuery = (query, source, onlyLocal = false) =>
    (dispatch, getState, getFirebase) => {
        const fs = getFirebase().firestore();
        const storage = getFirebase().storage();
        const agencyID = getState().agency.agencyID;

        // hook to collection database
        query.fsCollection = fs.collection(Agency.FS_COLLECTION_NAME)
            .doc(agencyID)
            .collection(Clipping.FS_COLLECTION_NAME)

        query.fStorage = storage.ref(`${Aclipp.FS_AGENCY_PREFIX}/${agencyID}`);

        if (!onlyLocal) {
            return dispatch(clippingAction.updateQuery({query}));
        }


    };


export const setCurrentClippingFromID = (clippingID) => {
    return (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();
        const fs = firebase.firestore();
        const state = getState();
        const clippings = state.clipping.data;
        const agencyID = state.agency.agencyID;
        const metricType = state[MetricType.FS_COLLECTION_NAME].data;
        const storage = firebase.storage();
        const storageRef = storage.ref(`${Aclipp.FS_AGENCY_PREFIX}/${agencyID}`);

        let clipping;

        if (clippings[clippingID]) {
            return dispatch(clippingAction.setCurrentClipping({
                current: clippings[clippingID].deepCopy
            }));
        } else {
            fs.collection(Agency.FS_COLLECTION_NAME)
                .doc(agencyID)
                .collection(Clipping.FS_COLLECTION_NAME)
                .doc(clippingID)
                .get()
                .then(snap => {
                    if (snap.exists) {

                        clipping = Clipping.fromFirestore({[clippingID]: snap.data()}, metricType);
                        // unwrap
                        clipping = clipping[clippingID];
                        let promise = [];

                        clipping.clip.forEach((clip) => {
                            promise.push(storageRef
                                .child(clip.imageStorageRef)
                                .getDownloadURL()
                                .then((url) => {
                                    clip.imageSource = url;
                                })
                                .catch((err) => {
                                    clipping.status = Clipping.STATUS.ERROR;
                                }));

                            if (clip.hasSmallImage) {
                                promise.push(storageRef
                                    .child(clip.smallImageStorageRef)
                                    .getDownloadURL()
                                    .then((url) => {
                                        clip.smallImageSource = url;
                                    })
                                    .catch((err) => {
                                        clipping.status = Clipping.STATUS.ERROR;
                                    }));
                            }
                        });

                        return Promise.all(promise)

                    } else {
                        throw new Error('File not Found')
                    }
                })
                .then(() => {
                    return dispatch(clippingAction.setCurrentClipping({current: clipping}));
                })
                .catch((err) => {
                    console.error({SET_CURRENT_CLIPPING: err});
                });
        }
    }
}; // set the state.report.current either from state.report.data (if id is included) otherwise load the report from firestore


export const setSearchInput = (searchInput = []) =>
    (dispatch, getState, getFirebase) =>
        dispatch(clippingAction.setSearchInput({searchInput}));

export const setSearchResult = (searchResult = []) => {
    return (dispatch, getState, getFirebase) => {
        if (Array.isArray(searchResult)) {
            searchResult = searchResult.filter((r) => r)
            dispatch(clippingAction.setSearchResult({searchResult}));
        } else {
            dispatch(clippingAction.setSearchResult({searchResult: []}));
        }
    }
};

export const setClippingColumn = (column = []) => {
    return (dispatch) => {
        dispatch(clippingAction.setClippingColumn({column}))
    };
}


