import { createSlice } from '@reduxjs/toolkit';
import {Monitor} from "@sascha_kirstein/aclipp-models";

const initialState = {
    current: new Monitor(),
    isLoaded: false,
};


const monitorSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        loadMonitor: (state, {payload: current}) => {
            state.current = current;
            state.isLoaded = true;
        },
    }
});

export const monitorAction = monitorSlice.actions;
export default monitorSlice.reducer;
