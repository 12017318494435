import {clippingAction} from "./reducers/clippingReducer";

export class ClippingFileUploader {

    constructor() {
        this._uploadTasks = [];
    }

    get taskCount() {
        return this._uploadTasks.length
    }

    /**
     * add a Clipping to the FileUploader
     * @param {UploadTask} uploadTask for a clipping.
     * @returns {Promise}
     */
    add(uploadTask) {
        //when the _uploadTasks Array is empty, start current task immediately
        if (this._uploadTasks.length === 0) {
            uploadTask.start()
        }
        this._uploadTasks.push(uploadTask);

        uploadTask.promise.finally(() => {
            // when the upload is done, delete upload task
            this.remove()
        });

        return uploadTask.promise
    }

    /**
     * removes the first element of the _uploadTasks
     */
    remove() {
        if (this._uploadTasks.length !== 0) {
            this._uploadTasks.shift()
        }

        // if there is another task in _uploadTasks start uploading
        if (this._uploadTasks.length !== 0) {
            this._uploadTasks[0].start()
        }

    }


    /**
     * returns a Clipping Object from the FileUploader
     * @param {string} ClippingID of the Clipping.
     * @returns {Clipping|null}
     */
    getClipping(ClippingID) {
        return this._uploadTasks.find(upload => upload.clipping.id === ClippingID)
    }

    /**
     * returns the Upload status of a Clipping
     * @param {string} ClippingID of the  Clipping.
     * @returns {Object|null}
     */
    getClippingStatus(ClippingID) {

        let result = null;

        this._uploadTasks.forEach((upload) => {

            if (upload.clipping.id === ClippingID) {
                result = {
                    total: upload.globalTotal,
                    transferred: upload.globalTransferred
                };
            }
        });

        return result
    }

    /**
     * ClippingFileUploader
     * @returns {Number} Bytes already been transferred
     */
    get globalTransferred() {
        let transferred = 0;
        this._uploadTasks.forEach((upload) => {
            transferred += upload.globalTransferred;
        });
        return transferred
    }

    /**
     * ClippingFileUploader
     * @returns {Number} total Bytes to be transferred
     */
    get globalTotal() {
        let total = 0;
        this._uploadTasks.forEach((upload) => {
            total += upload.globalTotal;
        });
        return total
    }

}

export class UploadTask {

    /**
     * Upload Task Object
     * @param {Clipping} clipping for which the file should get uploaded.
     * @param {Storage} storageRef for which the file should get uploaded.
     * @param {Function} dispatch function of the redux store for upload events.
     * @returns {Promise}
     */
    constructor(clipping, storageRef, dispatch) {
        this._clipping = clipping;
        this._storageRef = storageRef;
        this._dispatch = dispatch;

        this._task = {};

        this._globalTransferred = 0;
        this._globalTotal = 0;

        this._promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }

    /**
     * Upload Task
     * @returns {Number} Bytes already been transferred
     */
    get globalTransferred() {
        return this._globalTransferred;
    }

    /**
     * Upload Task
     * @returns {Number} total Bytes to be transferred
     */
    get globalTotal() {
        return this._globalTotal;
    }

    /**
     * Upload Task
     * @returns {Clipping} that is currently uploaded
     */
    get clipping() {
        return this._clipping;
    }

    /**
     * Upload Task
     * @returns {Promise} resolves when all files got uploaded
     */
    get promise() {
        return this._promise;
    }

    handleStateChanged = (clipID) => (snapshot) => {
        this._task[clipID].bytesTransferred = snapshot.bytesTransferred;
        this._task[clipID].totalBytes = snapshot.totalBytes;


        let globalTransferred = 0;
        let globalTotal = 0;

        Object.values(this._task).forEach((task) => {
            globalTransferred += task.bytesTransferred;
            globalTotal += task.totalBytes;
        });

        this._globalTransferred = globalTransferred;
        this._globalTotal = globalTotal;

        this._dispatch(
            clippingAction.clippingUploadProcess({
                clipping: this._clipping,
                total: globalTotal,
                transferred: globalTransferred
            })
        );
    };

    handleError = (clipID) => (err) => {
        console.error({CLIPPING_UPLOAD_ERROR: {err, clipID}});
        //this._dispatch({type: clippingAction.CLIPPING_UPLOAD_ERROR, err});
        this.reject(err)
    };

    handleCompletion = (clipID) => () => {
        this._task[clipID].complete = true;

        // Check upload status of all files
        const allComplete = Object.values(this._task).every(t => t.complete)

        // All files have been uploaded
        if (allComplete) {
            //this._dispatch({type: clippingAction.CLIPPING_UPLOAD_SUCCESS});
            this._dispatch( clippingAction.clippingUploadSuccess())
            this.resolve(this._clipping)
        }
    };

    /**
     * Start the File Task Upload
     */
    start() {
        const BASE64_IMG_START = 'data:image/'
        const metadata = {
            cacheControl: `public,max-age=${60 * 60 * 24 * 100}`, //cached for 100 days
        };

        this._clipping.clip.forEach(clip => {
            // Main Image Upload Task
            if (clip.imageSource.startsWith(BASE64_IMG_START)) {
                this._task[clip.id] = {
                    id: clip.id,
                    clipRef: (this._storageRef.child(clip.imageStorageRef)),
                    dataURL: clip.imageSource,
                    completed: false
                }

                // Small Image Upload Task
                if (clip.smallImageSource.startsWith(BASE64_IMG_START)) {
                    this._task['s-' + clip.id] = {
                        id: 's-' + clip.id,
                        clipRef: (this._storageRef.child(clip.smallImageStorageRef)),
                        dataURL: clip.smallImageSource,
                        completed: false
                    }
                }
            }
        })

        //if there is no task, then resolve promise
        if(Object.keys(this._task).length === 0){
            this.resolve(this._clipping)
        }

        const upload = async (task) => {
            const response = await fetch(task.dataURL)
            if(!response.ok){
                this.reject(new Error('Could not load dataURL: ' + task.id))
            }
            const imageBlob = await response.blob()
            const uploadTask = task.clipRef.put(imageBlob, metadata)

            uploadTask.on('state_changed',
                this.handleStateChanged(task.id),
                this.handleError(task.id),
                this.handleCompletion(task.id));

        }

        Object.values(this._task).forEach(task => upload(task))
    }

    pause() {
    }

    resume() {
    }

    cancel() {
    }
}