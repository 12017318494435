import {Agency, User} from "@sascha_kirstein/aclipp-models";
import {userAction} from "../reducers/userReducer";
import moment from 'moment'

export const loadUserFromFS = (fsUser) =>
    (dispatch, getState, getFirebase) => {
        const storage = getFirebase().storage();

        try {
            let users = User.fromFirestore(fsUser);

            const userWithPhoto = Object.values(users).filter((user) => {
                return user.hasPhoto
            });

            // at least one user has a profile photo
            if (userWithPhoto.length > 0) {
                const promise = [];

                userWithPhoto.forEach( (user) => {
                    promise.push(
                        storage.ref(user.imageStorageRef)
                            .getDownloadURL()
                            .then((url) => {
                                user.photoURL = url;
                            })
                            .catch(() => {
                                console.log('could not get download url of file', user);
                            }))
                });

                Promise.all(promise) //TODO do we need to do something with users with photo???
                    .then(() => dispatch(userAction.loadUser(users)));
            }
            dispatch(userAction.loadUser(users));
        } catch(err) {
            console.error({LOAD_USER_ERROR: err})
        }

};

export const updateUser = (user) => {
    return (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();
        const fs = firebase.firestore();
        const currentUser = firebase.auth().currentUser;
        let updatedUser = new User(user);

        const {userAgencyID} = getState().auth;

        try {
            let batch = fs.batch();
            batch.update(
                fs.collection(User.FS_COLLECTION_NAME)
                    .doc(currentUser.uid),
                updatedUser.toFirestore());

            batch.update(
                fs.collection(Agency.FS_COLLECTION_NAME)
                    .doc(userAgencyID)
                    .collection(User.FS_COLLECTION_NAME)
                    .doc(currentUser.uid),
                updatedUser.toFirestore());

            batch.commit()
                .catch((err) => {
                console.error({UPDATE_USER_ERROR: err})
            });

        } catch (err) {
            console.error({UPDATE_USER_ERROR: err})
        }
    }
};

export const deleteUser = (userID) =>
    (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();

        firebase.app()
            .functions('europe-west1')
            .httpsCallable("userDelete")({userID: userID})
            .catch((err) => {
                console.error({DELETE_USER_ERROR: err})
        });
};

export const createUser = (user) => {
    return (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();
        const _user = new User(user);
        const _agencyID = getState().agency.agencyID;

        if (_user.validate()) {
            firebase.app()
                .functions('europe-west1')
                .httpsCallable("userCreate")({user: _user, agencyID: _agencyID})
                .catch((err) => {
                    console.error({CREATE_USER_ERROR: err})
                });

        } else {
            console.error({CREATE_USER_ERROR: 'user is not valid'})
        }


    }

};

export const resendInvite = (userID) => {
    return (dispatch, getState, getFirebase) => {
        const firebase = getFirebase();
        const agencyID = getState().agency.agencyID;
        firebase.app()
            .functions('europe-west1')
            .httpsCallable("userSendInviteMail")({userID: userID, agencyID: agencyID})
            .catch((err) => {
                console.error({RESEND_INVITE_ERROR: err})
            });
    }
}
//updateprofile
export const setCurrentUser = (user) =>
    (dispatch, getState) => {

        const auth = getState().auth;
        const superUser = Boolean(auth.superUser);
        const connectedUser = Boolean(auth.connectedUser);

        if (user?.status === User.STATUS.PENDING) {
            user.status = User.STATUS.ACTIVE
            dispatch(updateUser(user))
        }
        return dispatch(userAction.setCurrentUser({current: user, superUser, connectedUser}))
    }


export const fullfillGoal = (goal) =>
    (dispatch, getState, getFirebase) => {
        const user = getState().user.current
        const firebase = getFirebase();
        const fs = firebase.firestore();
        const currentUser = firebase.auth().currentUser;
        const userAgencyID = getState().auth.userAgencyID;

        if (user.goal[goal] === undefined) {
            const batch = fs.batch();
            batch.update(
                fs.collection(User.FS_COLLECTION_NAME)
                    .doc(currentUser.uid),
                {[`goal.${goal}`]: moment().toDate()});

            batch.update(
                fs.collection(Agency.FS_COLLECTION_NAME)
                    .doc(userAgencyID)
                    .collection(User.FS_COLLECTION_NAME)
                    .doc(currentUser.uid),
                {[`goal.${goal}`]: moment().toDate()});

            batch.commit()
                .catch((err) => {
                    console.log({FULLFILL_GOAL_ERROR: err})
                });
        }
    }



