import firebase from '../config/fbConfig';


// own
import {configureStore} from "@reduxjs/toolkit";
import appReducer from "./reducers/appReducer";
import agencyReducer from "./reducers/agencyReducer";
import analyticReducer from "./reducers/analyticReducer";
import authReducer from "./reducers/authReducer";
import clientReducer from "./reducers/clientReducer";
import clippingReducer from "./reducers/clippingReducer";
import dashboardReducer from "./reducers/dashboardReducer";
import metricTypeReducer from "./reducers/metricTypeReducer";
import monitorReducer from "./reducers/monitorReducer";
import superUserReducer from "./reducers/superUserReducer";
import userReducer from "./reducers/userReducer";
import reportReducer from "./reducers/reportReducer";

export default configureStore({
    reducer: {
        app: appReducer,
        agency: agencyReducer,
        analytic: analyticReducer,
        auth: authReducer,
        client: clientReducer,
        clipping: clippingReducer,
        dashboard: dashboardReducer,
        metricType: metricTypeReducer,
        monitor: monitorReducer,
        superUser: superUserReducer,
        user: userReducer,
        report: reportReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        thunk: {extraArgument: () => firebase},
        serializableCheck: false //!TODO https://redux.js.org/style-guide/#do-not-put-non-serializable-values-in-state-or-actions
    }),
})
