import {createContext} from "react";
import {ClientDB} from "./clientDB";

const ClientDBContext = createContext();
const clientDB = new ClientDB()

window.clientDB = clientDB;

export function ClientDBProvider({children}){

    return (
        <ClientDBContext.Provider value={clientDB}>
            {children}
        </ClientDBContext.Provider>
    )
}

export default  ClientDBContext;