import localforage from "localforage";
import firebase from "../../config/fbConfig";
import {collection, getFirestore, onSnapshot, query, where} from "firebase/firestore";
import {getStorage, ref, getDownloadURL} from "firebase/storage";
import {WorkerPool} from "./utils";

export const ONLINE_ENDPOINT = "/api/v1/db/";
export const TEST_ENDPOINT = 'http://localhost:5001/dev-aclipp/us-central1/apiEndpoint' + ONLINE_ENDPOINT;

export class ClientDB {

    constructor() {
        this.collection = {};
        this.listeners = {};
        this.pendingDependency = [];
        this.workerPool = new WorkerPool(1);
    }

    async connect(collectionPath, {
        classRef,
        alias,
        dependency,
        transform,
        onlyRealtime,
        config = null
    }) {

        // check if collection has already been established
        if (this.collection[collectionPath]) {
            console.warn('collection already established for ' + collectionPath)
            return
        }

        // check dependencies
        await this._waitForDependency(collectionPath, dependency)
        // allow custom transform functions
        const _transform = transform ? transform : (data) => classRef.fromFirestore(data)
        // create collection entry
        const collection = this.collection[collectionPath] = {
            classRef,
            transform: _transform,
            alias,
            dependency,
            config
        };

        // alias is a shortcut to a collection
        if (alias) {
            this.collection[alias] = collection;
        }

        // save config to config path
        if (config) {
            await this.localforage.setItem(`${collectionPath}/config`, config);
        }
        // load cached data from localforage
        const cache = await this.localforage.getItem(collectionPath);


        if (cache) {
            collection.data = _transform(cache, this.collection)
            collection.ordered = Object.values(collection.data)
            collection.cacheLoaded = true;
        }

        if (!onlyRealtime) {
            const {timestamp, storageData} = await this._getStorageData(collectionPath);
            collection.data = collection.storageData = _transform(storageData, this.collection)
            collection.ordered = Object.values(collection.data)

            // cache storage data
            await this.localforage.setItem(collectionPath, storageData);

            // connect to Firestore
            collection.unsubscribe = this._connectToFirestore(collectionPath, timestamp);
        } else {
            // connect to Firestore
            collection.unsubscribe = this._connectToFirestore(collectionPath);
        }


        collection.isLoaded = true;

        // because collection is established - try to solve pending dependencies
        this.solveDependencies()
        // update all listeners
        this.updateListeners(collectionPath);
        this.updateListeners(alias);
    }

    disconnect(collectionPath) {
        if (this.collection[collectionPath]) {
            try {
                this.collection[collectionPath].unsubscribe()
            } catch (e) {
                console.warn('regular unsubscribe failed ' + collectionPath)
            }

            console.info('unsubscribe from: ' + collectionPath)
            delete this.collection[collectionPath];
        }

        // filter if this collectionPath is still in pending dependencies
        this.pendingDependency = this.pendingDependency.filter((d) => d.collectionPath !== collectionPath)
    }

    get localforage() {
        if (!this._localforage) {
            this._localforage = localforage.createInstance({
                name: 'aclipp',
                storeName: `clientDB`,
            })
        }
        return this._localforage
    }

    async _getStorageData(collectionPath) {

        // call cloud function
        const ID_Token = await firebase.auth().currentUser.getIdToken();
        const url = window.location.hostname === 'localhost' ? TEST_ENDPOINT : ONLINE_ENDPOINT;
        const res = await fetch(url + collectionPath, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${ID_Token}`,
            },
            redirect: 'follow'
        })
        const {path: storagePath, timestamp} = await res.json()

        // download the json file with collection data
        const storage = getStorage();
        const fileURL = await getDownloadURL(ref(storage, storagePath));
        const fileRes = await fetch(fileURL);
        const storageData = await fileRes.json();
        return {timestamp, storageData}

    }

    _connectToFirestore(collectionPath, timestamp) {
        // establish realtime connection, without timestamp get all documents
        const q = timestamp ?
            query(collection(getFirestore(), collectionPath),
                where('modified', '>', new Date(timestamp)))
            :
            query(collection(getFirestore(), collectionPath))

        return onSnapshot(q,
            (snap) => this.handleSnapshot(collectionPath, snap))
    }

    async _waitForDependency(collectionPath, dependency) {
        if (Array.isArray(dependency)) {
            const pendingDependency = dependency.filter((d) => !this.collection[d]?.isLoaded)
            if (pendingDependency.length > 0) {
                return new Promise((resolve) => {
                    this.pendingDependency.push({
                        collectionPath,
                        dependency: pendingDependency,
                        resolve
                    })
                })
            }
        }
    }

    solveDependencies() {
        // only open dependencies are kept
        this.pendingDependency = this.pendingDependency.filter((pendingDependency) => {
            // check if every dependency is already solved
            if (pendingDependency.dependency.every((d) => this.collection[d]?.isLoaded)) {
                // dependency can be resolved
                pendingDependency.resolve()
                return false
            }
            return true
        })
    }

    async handleSnapshot(collectionPath, snap) {

        const collection = this.collection[collectionPath];
        if (collection) {
            const data = {};
            snap.forEach((doc) => data[doc.id] = doc.data())
            collection.data = {...collection.storageData, ...collection.transform(data, this.collection)}
            collection.ordered = Object.values(collection.data)
            // update all listeners
            this.updateListeners(collection.listeners)
            // cache storage data
            const storageData = {};
            collection.ordered.forEach((el) => storageData[el.id] = el.toFirestore ? el.toFirestore() : el)
            await this.localforage.setItem(collectionPath, storageData);
            // remove cache
            //await this.clearAllCache(collectionPath)
        } else {
            console.warn('handle Snapshot active for disconnected collection: ' + collectionPath)
        }
    }

    updateListeners(collectionPath ) {
        if(Array.isArray(this.listeners[collectionPath])){
            this.listeners[collectionPath].forEach((listener) => {
                try {
                    listener({})
                } catch (e) {
                    console.warn('Could not trigger listener')
                }
            });
        }
    }

    register(collection, callback) {
        if (!this.listeners[collection]) {
            this.listeners[collection] = []
        }
        this.listeners[collection].push(callback)
    }

    unregister(collection, callback) {
        if (Array.isArray(this.listeners[collection])) {
            this.listeners[collection] =
                this.listeners[collection].filter((c) => c !== callback)
        }

    }

    async clearAllCache(path) {
        await this.workerPool.flush(path)
    }

    async addTask(task) {
        return this.workerPool.addTask(task)
    }

    removeTask(task) {
        return this.workerPool.removeTask(task)
    }
}