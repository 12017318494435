import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {Aclipp, Report} from '@sascha_kirstein/aclipp-models';
import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';
import {setMenuExtended} from '../../store/actions/appActions';
import {setCurrentReport} from '../../store/actions/reportActions';

import {
    Arrow2LeftIcon,
    ClippingsIcon,
    DashboardIcon,
    PowerIcon,
    ReportIcon,
    SettingsIcon,
    BrandIcon
} from '../icon/icons';
import MenuClippingButton from "./MenuClippingButton";


export const DRAWER_WIDTH_EXTENDED = 256;
export const DRAWER_WIDTH = 64;

export default function MenuDrawer() {
    const classes = useStyles();
    const {t} = useTranslation('layout');

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const menuIsExtended = useSelector(state => state.app.menuExtended);

    const navigate = useNavigate();
    let location = useLocation();

    const [_superUser, _isSuperUser] = useState(false);

    useEffect(
        () => {
            try {
                const superUser = Boolean(auth.superUser);
                _isSuperUser(superUser);
            } catch (e) {
            }
        }, [auth]
    );

    // Menu Labels
    let menuNames = [t('MenuDrawer.dashboard'), t('MenuDrawer.clippings'), t('MenuDrawer.reports'), t('MenuDrawer.brand'), t('MenuDrawer.settings')];
    // Menu Icons
    let menuIcons = [<DashboardIcon/>, <ClippingsIcon/>, <ReportIcon/>, <BrandIcon/>, <SettingsIcon/>];
    // corresponding url path
    let menuPaths = [Aclipp.ROUTES.DASHBOARD, Aclipp.ROUTES.CLIPPING, Aclipp.ROUTES.REPORT,
        Aclipp.ROUTES.CLIENT, Aclipp.ROUTES.SETTING];
    // menu active states
    let menuActive = [false, false, false, false, false];

    // Add SuperUser Menu Item
    if (_superUser) {
        menuNames.push('SuperUser');
        menuIcons.push(<PowerIcon/>);
        menuPaths.push(Aclipp.ROUTES.SUPERUSER);
        menuActive.push(false);
    }

    // determine the current active menu state
    const path = location.pathname.split('/');
    const activeMenuIndex = menuPaths.indexOf('/' + path[1]);

    if (activeMenuIndex !== -1) {
        menuActive[activeMenuIndex] = true;
    }

    const handleClickMenuItem = prop => () => {
        // TODO: REPLACE LATER
        if (prop === Aclipp.ROUTES.REPORT) {
            const report = new Report();
            report.status = Report.STATUS.INIT;
            dispatch(setCurrentReport(report));
        }
        navigate(prop);
    };

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: menuIsExtended,
                    [classes.drawerClose]: !menuIsExtended
                })}
                classes={{
                    paper: clsx(classes.drawerPaper, {
                        [classes.drawerOpen]: menuIsExtended,
                        [classes.drawerClose]: !menuIsExtended
                    })
                }}
                anchor="left"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.toolbar}
                    wrap='nowrap'
                >
                    <Grid item>
                        <Box
                            fontSize={20}
                            fontFamily="Sofia Pro Bold"
                            style={{paddingBottom: 4, cursor: menuIsExtended ? '' : 'pointer', position: 'relative'}}
                            onClick={() => {
                                menuIsExtended || dispatch(setMenuExtended(!menuIsExtended));
                            }}
                        >
                            {menuIsExtended ? 'aclipp' : 'a'}
                        </Box>
                    </Grid>

                    <Grid item
                          className={classes.arrowIcon}
                          onClick={() => {
                              dispatch(setMenuExtended(!menuIsExtended));
                          }}
                    >
                        <Arrow2LeftIcon/>
                    </Grid>


                </Grid>
                <Divider/>
                <MenuClippingButton
                />
                <List className={classes.list}>
                    {menuNames.map((text, index) => (
                        <MenuItem button
                                  key={text}
                                  selected={menuActive[index]}
                                  onClick={handleClickMenuItem(menuPaths[index])}
                                  className={classes.menuItem}
                        >
                            <ListItemIcon className={classes.listIcon}>{menuIcons[index]}</ListItemIcon>
                            <ListItemText className={classes.listText} primary={text}/>
                        </MenuItem>
                    ))}
                </List>
            </Drawer>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        overflow: 'hidden'
    },
    drawer: {
        width: DRAWER_WIDTH_EXTENDED,
        flexShrink: 0
    },
    drawerOpen: {
        width: DRAWER_WIDTH_EXTENDED,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: DRAWER_WIDTH
    },
    drawerPaper: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    toolbar: {
        backgroundColor: '#3b3a53',
        ...theme.mixins.toolbar,
        padding: '0px 24px 0px 24px',
        overflowX: 'hidden',
        width: DRAWER_WIDTH_EXTENDED
    },
    list: {
        padding: 0
    },
    listItem: {
        height: 56,
        selected: {
            backgroundColor: '#4d4c68'
        }
    },
    listIcon: {
        paddingTop: 4,
        paddingLeft: 6,
        minWidth: 48
    },
    listText: {
        height: 21
    },
    arrowIcon: {
        width: 20,
        height: 20,
        cursor: 'pointer',
        opacity: 0.5,
        transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
}));

const MenuItem = withStyles({
    root: {
        padding: '12px 16px 12px 16px',

        '&$selected': {
            backgroundColor: '#4d4c68',
            borderStyle: 'solid',
            borderLeft: 4,
            borderColor: '#e61f5c',
            paddingLeft: 12
        }
    },
    selected: {}

})(ListItem);
