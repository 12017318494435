import {createSlice, createAction} from "@reduxjs/toolkit";

const initialState = {
    claims: {},
    authedUser: {},
    accessLevel: null,
    connectedUser: null,
    isEmpty: true,
    isLoaded: false,
    superUser: false,
    userAgencyID: null,
    userID: null,
};



export const actionEffectUpdate = createAction('actionEffectUpdate', (payload) => {
    if (payload.errorName) {
        console.error(payload.err || payload.error);
        return {payload: payload.errorName}
    }

    return { payload }
}); // this can be used in other slices

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state,{payload}) => {
            //we can use here return, because we are changing whole state
           return {
               isLoaded: true,
               isEmpty: false,

               claims: payload.claims,
               authedUser: payload.authedUser,

               accessLevel: payload.claims.accessLevel,
               connectedUser: payload.claims.connectedUser,
               superUser: payload.claims.superUser,
               userAgencyID: payload.claims.agency,
               userID: payload.claims.user_id,
           }
        },
        logout : () => {
            return {
                ...initialState,
                isEmpty: true,
                isLoaded: true,
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase( actionEffectUpdate, (state, {payload}) => {
            state.authAction = payload;
        })
        //we can use also add matcher for searching endings or beginnings of type name
    }
});

export const authAction = authSlice.actions;

export default authSlice.reducer;