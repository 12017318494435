import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import React, {lazy, useState, Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {DRAWER_WIDTH, DRAWER_WIDTH_EXTENDED} from "./MenuDrawer";
// Actions
const SuperUserInfo = lazy(() => import("./SuperUserInfo"));
const TrialInfo = lazy(() => import("./TrialInfo"));
const TopMenu = lazy(() => import("./TopMenu"));
const ConnectedAgencyInfo = lazy(() => import("./ConnectedAgencyInfo"));
const Authorize = lazy(() => import("./Authorize"));
const TrialEndDialog = lazy(() => import("./TrialEndDialog"));



export default function TopNav() {

    const classes = useStyles();
    const {t} = useTranslation('layout');

    const menuExtended = useSelector(state => state.app.menuExtended);
    const user = useSelector(state => state.user.current);

    const [open, setOpen] = useState(false);

    return (
        <AppBar
            className={menuExtended ? classes.appBarEx : classes.appBar}
            elevation={1}
        >
            <Suspense fallback={<div/>}>
                <Toolbar style={{position: "relative"}}>

                    <Authorize>
                        <SuperUserInfo/>
                    </Authorize>

                    <ConnectedAgencyInfo/>
                    <TrialInfo/>
                    <TrialEndDialog/>

                    <IconButton
                        className={classes.account}
                        edge="end"
                        aria-label="account of current user"
                        aria-controls="account"
                        aria-haspopup="true"
                        onClick={() => {
                            setOpen(!open)
                        }}
                        color="inherit"
                        size="large">
                        <Typography>
                            {user.firstName}
                        </Typography>
                        <ArrowBackIosIcon className={classes.arrowBackIosIcon} style={{fontSize: 20}}/>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                            }}
                        >
                            <Avatar
                                src={user.photoURL}
                                className={classes.purpleAvatar}>
                                {user.initials}
                            </Avatar>
                        </Badge>
                    </IconButton>
                </Toolbar>

                <TopMenu open={open} onClose={() => {
                    setOpen(false)
                }}/>
            </Suspense>
        </AppBar>
    );
}

const useStyles = makeStyles(theme => ({
    appBar: {
        flexGrow: 1,
        position: 'fixed',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.04)',
        paddingLeft: DRAWER_WIDTH + 54,
        "@media screen and (max-width:1440px)": {
            paddingLeft: DRAWER_WIDTH
        }
    },
    appBarEx: {
        flexGrow: 1,
        position: 'fixed',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.04)',
        paddingLeft: DRAWER_WIDTH_EXTENDED + 54,
        "@media screen and (max-width:1440px)": {
            paddingLeft: DRAWER_WIDTH_EXTENDED
        }
    },
    account: {
        position: "absolute",
        right: 24
    },
    arrowBackIosIcon: {
        padding: 4,
        marginRight: 8,
        marginBottom: 4,
        transform: 'rotate(270deg)'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    purpleAvatar: {
        width: 40,
        height: 40,
        fontSize: 15,
        color: '#fff',
        backgroundColor: '#43425D'
    }
}));